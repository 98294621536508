.Spinner {
  animation: spin 1s ease-in-out infinite;
  border: 0.35rem solid var(--spinner-color-ring);
  border-radius: 50%;
  border-top-color: var(--spinner-color);
  display: inline-block;
  height: 3.5rem;
  width: 3.5rem;
}

.Spinner--lg {
  border-width: 0.5rem;
  height: 5rem;
  width: 5rem;
}

.Spinner--sm {
  border-width: 0.2rem;
  height: 2rem;
  width: 2rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.LeadBar {
  background: var(--brand-success);
  color: var(--color-white);
  text-align: center;
}

.LeadBar > .Modal__BtnClose {
  color: var(--color-white);
  opacity: 0.8;
  position: absolute;
  right: 0;
  transition: opacity 0.15s ease-in-out;
}

.LeadBar > .Modal__BtnClose:hover {
  opacity: 1;
}

.LeadBar__Inner {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.LeadBar__Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.LeadBar__Header > h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.LeadBar__Header > p {
  font-size: 0.75rem;
  font-weight: 300;
  margin: 0;
}

@media (min-width: 768px) {
  .LeadBar {
    height: var(--lead-bar-height);
    text-align: left;
  }

  .LeadBar__Inner {
    flex-direction: row;
  }

  .LeadBar__Header {
    height: var(--lead-bar-height);
    margin-bottom: 0;
    margin-top: 0;
  }

  .LeadBar__LeadForm {
    align-items: center;
    display: flex;
    margin-left: 1.5rem;
  }
}

@media (min-width: 991px) {
  .LeadBar__Header > h1 {
    font-size: 1.25rem;
  }

  .LeadBar__Header > p {
    font-size: 1rem;
  }
}

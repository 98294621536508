.AddressForm {
  line-height: 2rem;
  text-align: left;
  padding: 0.25rem;
}

.AddressForm__address {
  grid-area: address;
}

.AddressForm__email {
  grid-area: email;
}

.AddressForm__CustomField {
  padding: 0;
}

.AddressForm__input {
  border: 1px solid var(--color-gray-dark);
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.25;
  padding: 1rem;
  width: 100%;
}

.AddressForm__formGroup {
  margin-bottom: 1rem;
}

.AddressForm__label {
  font-weight: 600;
  margin-bottom: 1rem;
}

.AddressForm__label--light {
  color: var(--color-black);
}

.AddressForm__label--dark {
  color: var(--color-white);
}

.AddressForm__checkbox {
  padding-bottom: 1rem;
}

.AddressForm--lg .AddressForm__input {
  font-size: 1.25rem;
}

.Button.AddressForm__submit {
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: bold;
  grid-area: submit;
  padding: 0.75rem 1.5rem;
  width: 100%;
}

.AddressForm--lg .Button.AddressForm__submit {
  padding: 1rem 3rem;
}

.AddressForm__map {
  grid-area: map;
  max-height: 16rem;
  min-height: 16rem;
}

.AddressForm__locale {
  grid-area: locale;
}

@media (min-width: 768px) {
  .AddressForm {
    align-items: flex-start;
    display: grid;
    grid-gap: 0.5rem 1rem;
    grid-template-areas:
      'address address submit'
      'map map map'
      'locale none none';
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-rows: max-content;
  }

  .AddressForm__formGroup {
    margin-bottom: 0;
  }
}

.checkboxContainer {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  margin: 0.5rem 0;
}

.checkboxContainer label {
  align-items: center;
  display: flex;
  line-height: 1.25;
}

.checkboxIcon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.checkbox {
  opacity: 0; /* Hide it */
}

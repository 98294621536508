.PlaceField__input-container {
  position: relative;
}

.PlaceField__dropdown-container {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-dark);
  border-radius: 0 0 0.25rem 0.25rem;
  border-top-color: var(--color-gray);
  color: var(--color-black);
  left: 0;
  margin-top: -0.25rem;
  max-height: calc(100vh - 3rem);
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
}

.PlaceField__suggestion {
  line-height: 1.5;
  padding: 0.5rem 1rem;
  text-align: left;
  cursor: pointer;
}

.PlaceField__suggestion:nth-child(even) {
  background-color: var(--color-gray-extra-light);
}

.PlaceField__suggestion--active,
.PlaceField__suggestion:hover {
  background-color: var(--color-light);
}

.PlaceField__dropdown-footer {
  border-top: 1px solid var(--color-gray);
  padding: 0.5rem 0.5rem 0 0.5rem;
  text-align: right;
}

.PlaceField__dropdown-footer > img {
  max-width: 150px;
}

.PlaceField__Hint {
  color: var(--color-gray-dark);
  display: block;
  line-height: 1rem;
  margin-top: 0.75rem;
  opacity: 0.7;
}

@media (min-width: 768px) {
  .PlaceField__Hint {
    margin-top: 0;
  }
}

.app {
  margin: 0 auto;
  max-width: 800px;
  padding: 3em 1em;
  text-align: center;
}

.browsers {
  list-style: none;
  margin: 0;
  padding: 3em 0;
}

.browsers > li {
  display: inline-block;
  margin: 0 1em;
}

.browsers a svg {
  color: var(--color-black);
  display: block;
  margin: 0 auto 0.5em auto;
}

.browsers a {
  background: var(--color-gray-extra-light);
  display: block;
  padding: 1em;
}

.browsers a:hover {
  background: var(--color-gray);
}

.AddressModal__CurrentAddress {
  align-items: center;
  border-bottom: 1px solid var(--color-gray);
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  margin-bottom: 1rem;
  padding: 2rem 0;
  text-align: center;
}

.AddressModal__CurrentAddress svg {
  color: var(--color-primary);
}

@media (min-width: 768px) {
  .Modal__Body .AddressForm {
    display: flex;
    flex-direction: column;

    .AddressForm__formGroup {
      margin-bottom: 2rem;
      width: 100%;

      small {
        margin-top: 0.5rem;
      }
    }

    .Button {
      margin: 0 auto 1rem;
      width: unset;
    }
  }
}

.form {
  margin-bottom: 1rem;
}

.textField {
  border: 1px solid var(--color-gray);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
  padding: 0.375rem 0.5rem;
  width: 100%;
}

.submitButton {
  background: var(--color-white);
  border-radius: 3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
}

@media (min-width: 768px) {
  .form {
    margin-bottom: 0;
  }

  .textField {
    margin-bottom: 0;
    margin-right: 1rem;
    padding: 0.75rem 1rem;
    width: auto;
  }
}

.FacebookLoginButton {
  align-items: center;
  background: #4267b2; /* Facebook blue */
  border: 2px solid #4267b2;
  border-radius: 0.25rem;
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  padding: 0;
  width: 100%;
}

.FacebookLoginButton__Content {
  align-items: center;
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: 2.5rem;
  width: 100%;
}

.FacebookLoginButton:hover {
  background: #365899;
  border-color: #365899;
}

.FacebookLoginButton__icon {
  align-items: center;
  background: var(--color-white);
  border-radius: 0.25rem 0 0 0.25rem;
  color: #4267b2;
  display: flex;
  height: 100%;
  justify-content: center;
}

.FacebookLoginButton__inner {
  flex: 1;
}

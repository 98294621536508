.EmailBallotForm {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.EmailBallotForm__Email {
  border: 1px solid var(--color-gray);
  border-radius: 3rem;
  font-size: 1rem;
  grid-area: email;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 100%;
}

.Button.EmailBallotForm__Submit {
  background: var(--brand-danger);
  border-radius: 3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  grid-area: submit;
  padding: 1rem 3rem;
}

@media (min-width: 768px) {
  .EmailBallotForm {
    display: grid;
    grid-gap: 1rem;
    grid-template-areas: 'email submit';
    grid-template-columns: 2fr 1fr;
  }

  .EmailBallotForm__Email {
    margin-bottom: 0;
  }

  .Button.EmailBallotForm__Submit {
    width: 100%;
  }
}

.Button {
  border: 1px solid transparent;
  border-radius: 10rem;
  /* something big so corners always look round */
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    opacity 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.Button:active,
.Button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.Button__inner {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.Button .Spinner {
  border-color: #ffc3c2;
  border-top-color: var(--brand-danger);
  border-width: 0.25rem;
  height: 2rem;
  left: calc(50% - 1rem);
  position: absolute;
  top: calc(50% - 1rem);
  width: 2rem;
}

/* Sizes */
.Button--lg {
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
}

.Button--sm {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
}

/* Block level */
.Button--block {
  display: block;
  width: 100%;
}

/* Types */
.Button--black {
  background: var(--color-black);
  border-color: var(--color-black);
  color: var(--color-white);
}

.Button--link {
  background: transparent;
  border: none;
  color: var(--link-color);
  padding: 0;
}

.Button--danger,
a.Button--danger {
  background-color: var(--brand-danger);
  border-color: var(--brand-danger);
  color: var(--color-white);
}

.Button--danger:active,
.Button--danger:hover {
  background-color: var(--brand-danger);
  border-color: var(--brand-danger);
  color: var(--color-white);
}

.Button--default,
a.Button--default {
  background-color: var(--color-gray-dark);
  border-color: var(--color-gray-dark);
  color: var(--color-white);
}

.Button--default:active,
.Button--default:hover {
  background-color: var(--color-gray-dark);
  border-color: var(--color-gray-dark);
  color: var(--color-white);
}

.Button--primary,
a.Button--primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-white);
}

.Button--primary:active,
.Button--primary:hover {
  background: var(--color-primary-darkened);
  border-color: var(--color-primary-darkened);
  color: var(--color-white);
}

.Button--secondary,
a.Button--secondary {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--color-white);
}

.Button--secondary:active,
.Button--secondary:hover {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--color-white);
}

.Button--success {
  background-color: var(--brand-success);
  border-color: var(--brand-success);
  color: var(--color-white);
}

.Button--success:active,
.Button--success:hover {
  background: var(--brand-success);
  border-color: var(--brand-success);
  color: var(--color-white);
}

.Button--warning {
  background-color: var(--brand-warning);
  border-color: var(--brand-warning);
  color: var(--color-black);
}

.Button--warning:active,
.Button--warning:hover {
  background: var(--brand-warning);
  border-color: var(--brand-warning);
  color: var(--color-white);
}

.Button--white {
  background: var(--color-white);
  border-color: var(--color-white);
  color: var(--color-black);
}

a.Button--panel,
.Button--panel {
  background: var(--color-white);
  border-radius: 0;
  box-shadow: 0 0 8px 0 var(--color-gray);
  color: var(--color-black);
  font-weight: bold;
  line-height: 1.25;
  margin: 0 1rem;
  padding: 1rem;
  text-align: left;
  white-space: normal;
}

.Button--panel:active,
.Button--panel:hover {
  box-shadow: 0 0 8px 0 var(--color-gray-dark);
}

/* Disabled */
.Button--disabled {
  background-color: var(--brand-disabled);
  border-color: var(--brand-disabled);
  cursor: not-allowed;
  pointer-events: none;
}

/* Outline */
.Button--outline,
a.Button--outline {
  background-color: transparent;
}

.Button--outline.Button--danger,
a.Button--outline.Button--danger {
  color: var(--brand-danger);
}

.Button--outline.Button--danger:active,
.Button--outline.Button--danger:hover,
a.Button--outline.Button--danger:hover {
  color: var(--color-white);
}

.Button--outline.Button--default,
a.Button--outline.Button--default {
  color: var(--color-gray-dark);
}

.Button--outline.Button--default:active,
.Button--outline.Button--default:hover,
a.Button--outline.Button--default:hover {
  color: var(--color-white);
}

.Button--outline.Button--primary,
a.Button--outline.Button--primary {
  color: var(--color-primary);
}

.Button--outline.Button--primary:active,
.Button--outline.Button--primary:hover,
a.Button--outline.Button--primary:hover {
  color: var(--color-white);
}

.Button--outline.Button--secondary,
a.Button--outline.Button--secondary {
  color: var(--color-secondary);
}

.Button--outline.Button--secondary:active,
.Button--outline.Button--secondary:hover,
a.Button--outline.Button--secondary:hover {
  color: var(--color-white);
}

.Button--outline.Button--success,
a.Button--outline.Button--success {
  color: var(--brand-success);
}

.Button--outline.Button--success:active,
.Button--outline.Button--success:hover,
a.Button--outline.Button--success:hover {
  color: var(--color-white);
}

.Button--outline.Button--warning,
a.Button--outline.Button--warning {
  color: var(--brand-warning);
}

.Button--outline.Button--warning:active,
.Button--outline.Button--warning:hover,
a.Button--outline.Button--warning:hover {
  color: var(--color-white);
}

.Button--outline.Button--disabled,
a.Button--outline.Button--disabled {
  color: var(--brand-disabled);
}

.Button--outline.Button--white,
a.Button--outline.Button--white {
  color: var(--color-white);
}

.Button--with-icon svg {
  margin-right: 0.5rem;
}

.Button--panel.Button--with-icon svg {
  font-size: 2rem;
  margin-right: 1rem;
}

.Button--with-icon-only svg {
  margin-right: 0;
}

.Button--circle {
  height: 2rem;
  width: 2rem;
}

a.Button--ssc {
  border: none;
  border-radius: 0.25rem;
  box-shadow: var(--color-black) 2px 2px 0;
  flex: 1;
  font-family: var(--font-family-headings--fancy);
  font-size: 0.875rem;
  font-weight: bold;
}

.ecNavButton {
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem;

  @media (max-width: 576px) {
    padding: 0.5rem;
  }
}

.Notification {
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  line-height: 1.375;
  margin-bottom: 1.5rem;
  padding: 1rem;
  text-align: left;
}

.Notification__body {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%;
}

.Notification__close {
  background: transparent;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  opacity: 0.7;
  padding: 0;
  text-align: right;
}

.Notification--warning .Notification__close {
  color: var(--color-black);
}

.Notification__close:hover {
  opacity: 1;
}

.Notification__Icon {
  font-size: 1.75rem;
}

/* Variants */
.Notification--danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.Notification--info {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
}

.Notification--success {
  background-color: var(--brand-success);
  border-color: var(--brand-success);
  color: var(--color-white);
}

.Notification--warning {
  background-color: #fff3cd;
  color: #856404;
}

/* Sizes */
.Notification--sm {
  padding: 0.5rem 1rem;
}

.Notification--sm .Notification__Icon {
  font-size: 1.25rem;
}

.scene {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.message {
  color: var(--color-gray-dark);
  font-size: 1.25rem;
  margin-top: 1rem;
}
